<template>
  <div class="panel-main box is-radiusless pt-6 pl-0" :class="{'open': isSidebarOpen }">
      <div id="" class="panel-main__inner mt-3">
          <div id="panel-main__content">
          <slot></slot>
          </div>
      </div>
      <button class="button is-white panel-main__close" @click.prevent="toggleSidebar">
        <span class="icon">
          <unicon name="multiply" fill="#000" />
        </span>
      </button>
  </div>
</template>

<script>
  import { reactive, watch } from "vue";
  import useGeneral from '@/use/useGeneral'
  export default {
    setup() {
      const { isSidebarOpen, toggleSidebar} = useGeneral();

      // const state = reactive({
      //   noscroll: isSidebarOpen
      // })


      // watch(
      //   () => state.noscroll,
      //   (scroll) => scroll ? document.body.classList.add("noscroll") : document.body.classList.remove("noscroll")
      // )


      return {
        isSidebarOpen,
        toggleSidebar
      }
    }
  }
</script>

<style lang="scss" scoped>
  .panel-main {
    position: fixed;
    right: 0;
    top: 0;
    width: 30vw;
    height: 100vh;
    z-index: 1000;
    transition: all 0.5s;
    transform: translate3d(100%, 0, 0);
    visibility: visible;

    // &:before {
    //   content: '';
    //   display: block;
    //   background: rgba(#000,0.8);
    //   width: 100vw;
    //   height: 100vh;
    //   z-index: 0;
    //   left: 0;
    //   position: fixed;
    //   top: 0;
    // }


    &.extended {
      width: 50vw;
    }

    &.open {
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }


    &__close {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 100;
    }

    &__inner {
      overflow: hidden;
      overflow-y: auto;
      height: 90vh;
      padding-bottom: 2rem;
      position: relative;
      z-index: 10;
    }
  }
</style>
